import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import { getGuid } from 'owa-guid';
import { getAccountFromMsal } from './utils/MsalAccounts';
import { getMsalInstance } from './initializeMsalLibrary';
import { getTypeHintFromMailboxInfo } from './getTypeHint';
import { logStartCoreUsage } from 'owa-analytics-start';
import type { SilentRequest, AuthenticationResult } from '@azure/msal-browser-1p';
import { getScopes } from './utils/getScopes';
import { getAuthority } from './utils/getAuthority';
import getSilentRedirectUri from './utils/getSilentRedirectUri';
import { getConfig } from 'owa-service/lib/config';
import { setItem } from 'owa-local-storage';

enum State {
    Scheduled,
    Skip,
    Pending,
}
const BackgroundRefreshState = new Map<string, State>();

export async function setupBackgroundTokenRefresh(mailboxInfo: MailboxInfo) {
    if (!getConfig().isFeatureEnabled('auth-msaljs-autoRefresh')) {
        return;
    }

    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const state = BackgroundRefreshState.get(key);
    if (state != undefined) {
        return;
    }

    BackgroundRefreshState.set(key, State.Pending);

    const msalInstance = await getMsalInstance();
    const account = getAccountFromMsal(msalInstance, mailboxInfo);
    const signInState = account?.idTokenClaims?.signin_state;
    const isKmsiEnabled = signInState != undefined && (signInState as string[]).includes('kmsi');
    if (!isKmsiEnabled) {
        BackgroundRefreshState.set(key, State.Skip);
        return;
    }

    const delayInMs = 4 * 60 * 60 * 1000 + getRandomDelayInMs(); // 4 hours + random number to avoid spikes

    setInterval(() => {
        forceRefreshTokens(mailboxInfo);
    }, delayInMs);

    BackgroundRefreshState.set(key, State.Scheduled);
}

/**
 * Force renew Refresh Token in the background, to ensure valid tokens for longer period of time, and reduce delays for subsequent token requests.
 * Note: ssoSilent doesn't work when 3rd party cookies are blocked. In that case, renewing Refresh Token would fail silently and user session will continue using existing RT while it's valid.
 */
async function forceRefreshTokens(mailboxInfo: MailboxInfo): Promise<void> {
    const startTime = self.performance.now();

    const typeHint = getTypeHintFromMailboxInfo(mailboxInfo);
    const correlationId = getGuid();
    const msalInstance = await getMsalInstance();
    const account = getAccountFromMsal(msalInstance, mailboxInfo);
    let authResult: AuthenticationResult | null = null;
    let errorCode;
    let errorMessage;

    if (account) {
        const forceRefreshRequest: SilentRequest = {
            account,
            scopes: getScopes(typeHint),
            authority: getAuthority(typeHint),
            redirectUri: getSilentRedirectUri(),
            correlationId,
            forceRefresh: true, // Force refresh Access Token
            refreshTokenExpirationOffsetSeconds: 72000, // Force renew Refresh Token if it expires in 20 hrs
        };

        authResult = await msalInstance.acquireTokenSilent(forceRefreshRequest).catch(error => {
            errorCode = error.errorCode;
            errorMessage = error.errorMessage;
            return null;
        });

        if (authResult?.expiresOn) {
            var tokenExpiry = authResult.expiresOn;
            setItem(self, 'msalexp', tokenExpiry.toString());
        }
    }

    logStartCoreUsage('Msal-BackgroundRefresh', {
        correlationId,
        accountType: typeHint,
        isAccountPresent: !!account,
        isSuccess: !!authResult,
        errorCode,
        errorMessage,
        latency: self.performance.now() - startTime,
        pageVisible: self.document.visibilityState === 'visible',
    });
}

function getRandomDelayInMs(): number {
    const maxInMs = 10 * 60 * 1000; // 10 minutes
    return Math.floor(Math.random() * maxInMs);
}
