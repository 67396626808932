import type { AccountInfo } from '@azure/msal-browser-1p';
import type { MailboxInfo } from 'owa-client-types';
import { getItem } from 'owa-local-storage';
import type WebSessionType from 'owa-service/lib/contract/WebSessionType';
import { getAccountScopeUserSettings } from 'owa-session-store/lib/selectors/getAccountScopeUserSettings';

export function getLoginHint(
    msalAccount?: AccountInfo,
    mailboxInfo?: MailboxInfo,
    username?: string
): string | undefined {
    if (msalAccount) {
        return msalAccount.idTokenClaims?.login_hint || msalAccount.username;
    } else if (mailboxInfo) {
        const sessionData = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
        return (
            sessionData?.LoginHint ??
            (sessionData?.WebSessionType !== 0
                ? sessionData?.NotManagedEmailAddresses?.[0]
                : undefined) ?? // Pass original SMPT to MSAL for EASI ID accounts. NotManagedEmailAddresses gets populated for EASI ID accounts only.
            sessionData?.LogonEmailAddress ?? // For scenarios such as shared mailboxes, UPN would be set to the mailbox instead of logged in user
            sessionData?.UserPrincipalName ??
            mailboxInfo?.userIdentity
        );
    } else if (username) {
        return username;
    }

    return getItem(self, 'login_hint') ?? undefined; // return login_hint claim value stored in cache if available.
}
