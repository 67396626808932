import { isAccountSourceListStoreInitialized } from 'owa-account-source-list-store';
import { getAnchorMailboxMailboxInfo } from 'owa-anchormailbox/lib/anchormailboxmailboxinfo';
import { getAnchorMailboxHeaderForExplicitLogon } from 'owa-anchormailbox/lib/getAnchorMailboxHeaderForExplicitLogon';
import type { MailboxInfo } from 'owa-client-types';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { getActiveAccountFromMsal } from './utils/MsalAccounts';
import { EXCHANGE_CONSUMER_TENANT_ID } from './utils/constants';
import { getMsalInstance } from './initializeMsalLibrary';
import { getAuthTokenMsal } from './getAuthTokenMsal';
import { isMsalEnabledForConsumer } from './isMsalEnabledForConsumer';
import { isMsalEnabledForBusiness } from './isMsalEnabledForBusiness';

export async function getAnchorMailbox(mailboxInfo?: MailboxInfo): Promise<string> {
    //prevent routing issue in case of explicit logon (like shared mailboxes)
    const header = getAnchorMailboxHeaderForExplicitLogon();
    if (!!header) {
        return header;
    }

    const zeroAccountState = !isAccountSourceListStoreInitialized() && !mailboxInfo;

    // If we are in zero account state, get active account from MSAL if available
    if (zeroAccountState) {
        // Let login complete before getting active account
        await getAuthTokenMsal();

        return getAnchorMailboxFromMsalAccount();
    }

    return getAnchorMailboxMailboxInfo(mailboxInfo ?? getModuleContextMailboxInfo());
}

export async function getAnchorMailboxFromMsalAccount(): Promise<string> {
    const msalInstance = await getMsalInstance();
    const account = getActiveAccountFromMsal(msalInstance);

    const puid = account?.idTokenClaims?.puid;
    const tenantId = account?.idTokenClaims?.tid;
    const username = account?.username;
    let puidRoutingHint;
    let smtpRoutingHint;

    if (isMsalEnabledForBusiness()) {
        puidRoutingHint = !!puid ? `PUID:${puid}@${tenantId}` : undefined;
        smtpRoutingHint = account ? `SMTP:${username}` : undefined;
    } else if (isMsalEnabledForConsumer()) {
        puidRoutingHint = !!puid ? `PUID:${puid}@${EXCHANGE_CONSUMER_TENANT_ID}` : undefined;
        smtpRoutingHint = account ? `MSA:${username}` : undefined;
    }

    return puidRoutingHint ?? smtpRoutingHint ?? '';
}
